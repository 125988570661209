
import { dispatchGetInvoiceDetails, dispatchGetRecentInvoiceDetails, dispatchRemoveFromCart, dispatchToggleProductInCart } from '@/store/products/actions';
import { readInvoiceDetail, readProductsInvoice } from '@/store/products/getters';
import { commitSetProductsInvoice } from '@/store/products/mutations';
import { getLocalSessionId } from '@/utils';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
    },
})

export default class Cart extends Vue {
    public counterNumber = 1;
    public price = 2215;
    public totalPrice = (this.price * this.counterNumber);
    public productPriceWarehouse: any = {
        choices: [],
    };
    public loadingState: any = {
        page: true,
    };

    public async mounted() {
    const response = await dispatchGetRecentInvoiceDetails(this.$store, {anon_session_id: getLocalSessionId(),});
    if (response) {
      commitSetProductsInvoice(this.$store, response);
    }
    this.loadingState.page = false;
  }

  public async handleInputCountNumber(id) {
    let inCart: any = null;
    if (this.invoiceDetails) {
      for (let i in this.invoiceDetails.items) {
        // @ts-ignore
        if (this.invoiceDetails.items[i].object_id === id) {
          inCart = {
            id: this.invoiceDetails.items[i].id,
            quantity: this.invoiceDetails.items[i].quantity,
          };
        }
      }
    }
    if (inCart) {
      if (inCart.quantity && Number(inCart.quantity) && Number(inCart.quantity) > 0) {
        await dispatchToggleProductInCart(this.$store, {invoice_item: inCart.id, action: 'count', quantity: Number(inCart.quantity)});
      }
    }
  }

  public async handleAddToCart(id) {
    let inCart: any = null;
    if (this.invoiceDetails) {
      for (let i in this.invoiceDetails.items) {
        if (this.invoiceDetails.items[i].object_id === id) {
          inCart = {
            id: this.invoiceDetails.items[i].id,
            quantity: this.invoiceDetails.items[i].quantity,
          };
        }
      }
    }
    if (inCart) {
      await dispatchToggleProductInCart(this.$store, {invoice_item: inCart.id, action: 'add'});
    }
  }

   public async handleRemoveFromCart(id) {
    if (this.invoiceDetails) {
      for (let i in this.invoiceDetails.items) {
        if (this.invoiceDetails.items[i]) {
          if (this.invoiceDetails.items[i].object_id === id && this.invoiceDetails.items[i].quantity > 1) {
            await dispatchToggleProductInCart(
              this.$store, {invoice_item: this.invoiceDetails.items[i].id, action: 'remove'},
            );
          }
        }
      }
    }
  }

  public async handleDeleteFromCart(id) {
    setTimeout(async () => {
      await dispatchRemoveFromCart(this.$store, {id, anon_session_id: getLocalSessionId()});
      if (this.productsInvoice) {
        dispatchGetInvoiceDetails(this.$store, {invoice_id: this.productsInvoice, anon_session_id: getLocalSessionId()});
      }
    }, 500);
  }
  get invoiceDetails() {
    return readInvoiceDetail(this.$store);
  }
  get productsInvoice() {
    return readProductsInvoice(this.$store);
  }
}
